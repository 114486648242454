import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'alert',
    loadChildren: () => import('./pages/alert/alert.module').then( m => m.AlertPageModule)
  },
  {
    path: 'action-sheet',
    loadChildren: () => import('./pages/action-sheet/action-sheet.module').then( m => m.ActionSheetPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./pages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'datas',
    loadChildren: () => import('./pages/datas/datas.module').then( m => m.DatasPageModule)
  },
  {
    path: 'grid',
    loadChildren: () => import('./pages/grid/grid.module').then( m => m.GridPageModule)
  },
  {
    path: 'input',
    loadChildren: () => import('./pages/input/input.module').then( m => m.InputPageModule)
  },
  {
    path: 'textarea',
    loadChildren: () => import('./pages/textarea/textarea.module').then( m => m.TextareaPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./pages/slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'toast',
    loadChildren: () => import('./pages/toast/toast.module').then( m => m.ToastPageModule)
  },
  {
    path: 'addcliente',
    loadChildren: () => import('./pages/addcliente/addcliente.module').then( m => m.AddclientePageModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
 
   {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'addcliente/:id/:nome/:telefone/:email',
    loadChildren: () => import('./pages/addcliente/addcliente.module').then( m => m.AddclientePageModule)
  },
 
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'detalhes-do-pedido',
    loadChildren: () => import('./pages/detalhes-do-pedido/detalhes-do-pedido.module').then( m => m.DetalhesDoPedidoPageModule)
  },
  {
    path: 'detalhes-do-pedido/:id/:id_do_deposito/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:nota_fiscal/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:idDoDeposito/:cep',
    loadChildren: () => import('./pages/detalhes-do-pedido/detalhes-do-pedido.module').then( m => m.DetalhesDoPedidoPageModule)
  },
  {
    path: 'pedidos-acaminho',
    loadChildren: () => import('./pages/pedidos-acaminho/pedidos-acaminho.module').then( m => m.PedidosACaminhoPageModule)
  },
  {
    path: 'detalhes-dos-pedidos-acaminho',
    loadChildren: () => import('./pages/detalhes-dos-pedidos-acaminho/detalhes-dos-pedidos-acaminho.module').then( m => m.DetalhesDosPedidosACaminhoPageModule)
  },
  {
    path: 'detalhes-dos-pedidos-acaminho/:id/:id_do_deposito/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:nota_fiscal/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:cep',
    loadChildren: () => import('./pages/detalhes-dos-pedidos-acaminho/detalhes-dos-pedidos-acaminho.module').then( m => m.DetalhesDosPedidosACaminhoPageModule)
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./pages/cadastro/cadastro.module').then( m => m.CadastroPageModule)
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule)
  },
  {
    path: 'configuracoes-de-busca',
    loadChildren: () => import('./pages/configuracoes-de-busca/configuracoes-de-busca.module').then( m => m.ConfiguracoesDeBuscaPageModule)
  },
  {
    path: 'home/:usuario/:senha/:idDoDeposito',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'inicio/:idDoDeposito',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'pedidos-acaminho/:idDoDeposito',
    loadChildren: () => import('./pages/pedidos-acaminho/pedidos-acaminho.module').then( m => m.PedidosACaminhoPageModule)
  },
  {
    path: 'relatorios/:idDoDeposito',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'relatori-gas',
    loadChildren: () => import('./pages/relatori-gas/relatori-gas.module').then( m => m.RelatoriGasPageModule)
  },
  {
    path: 'relatorio-agua',
    loadChildren: () => import('./pages/relatorio-agua/relatorio-agua.module').then( m => m.RelatorioAguaPageModule)
  },
  {
    path: 'historico',
    loadChildren: () => import('./pages/historico/historico.module').then( m => m.HistoricoPageModule)
  },
  {
    path: 'impressao-nota',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'impressao-nota/:id/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:cep/:destino_da_impressao',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'gerenciamento-de-usuario',
    loadChildren: () => import('./pages/gerenciamento-de-usuario/gerenciamento-de-usuario.module').then( m => m.GerenciamentoDeUsuarioPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
